import React from 'react';
import { Link } from '@backstage/core-components';
import {
  BooleanCheck,
  CheckResultRenderer,
} from '@backstage-community/plugin-tech-insights';
import { CheckResult } from '@backstage-community/plugin-tech-insights-common';
import { Box, makeStyles } from '@material-ui/core';
import { MoreInfo } from './MoreInfo';
import HelpOutline from '@material-ui/icons/HelpOutline';

// this is exported in @backstage-community/plugin-tech-insights-backend-module-jsonfc, but we can't bring backend modules to the frontend
const JSON_RULE_ENGINE_CHECK_TYPE = 'json-rules-engine';

const useStyles = makeStyles({
  checkmark: {
    alignSelf: 'flex-start',
  },
});

function Component(checkResult: CheckResult) {
  const { checkmark } = useStyles();
  const facts = Object.values(checkResult.facts).map(i => i.value);
  const containsFacts = facts.every(i => Object.keys(i).length > 0);
  return (
    <Box className={checkmark} pt={2}>
      {containsFacts ? (
        <BooleanCheck checkResult={checkResult} />
      ) : (
        <HelpOutline color="disabled" />
      )}
    </Box>
  );
}

function Description(checkResult: CheckResult) {
  const link = checkResult.check.metadata?.documentationLink;
  return (
    <>
      {checkResult.check.description}
      <br />
      {link && <Link to={link}>Documentation</Link>}
      <MoreInfo checkResult={checkResult} />
    </>
  );
}

function isFailed(checkResult: CheckResult): boolean {
  return checkResult.result === false;
}

export const CustomCheckResultRenderer: CheckResultRenderer = {
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  component: Component,
  description: Description,
  isFailed: isFailed,
};
