import { TopLevelCondition } from 'json-rules-engine';

export const JSON_RULE_ENGINE_CHECK_TYPE = 'json-rules-engine';

type Rule = {
  conditions: TopLevelCondition;
  name?: string;
  priority?: number;
};

interface TechInsightCheck {
  /**
   * Unique identifier of the check
   *
   * Used to identify which checks to use when running checks.
   */
  id: string;
  /**
   * Type identifier for the check.
   * Can be used to determine storage options, logical routing to correct FactChecker implementation
   * or to help frontend render correct component types based on this
   */
  type: string;
  /**
   * Human readable name of the check, may be displayed in the UI
   */
  name: string;
  /**
   * Human readable description of the check, may be displayed in the UI
   */
  description: string;
  /**
   * A collection of string referencing fact rows that a check will be run against.
   *
   * References the fact container, aka fact retriever itself which may or may not contain multiple individual facts and values
   */
  factIds: string[];
  /**
   * Metadata to be returned in case a check has been successfully evaluated
   * Can contain links, description texts or other actionable items
   */
  successMetadata?: Record<string, any>;
  /**
   * Metadata to be returned in case a check evaluation has ended in failure
   * Can contain links, description texts or other actionable items
   */
  failureMetadata?: Record<string, any>;
}

export interface TechInsightJsonRuleCheck extends TechInsightCheck {
  rule: Rule;
}

export interface Metadata {
  present: boolean;
  valid: boolean;
}

export interface Tenpureto {
  present: boolean;
  lastUpdate: string;
}

export type Java = Record<string, Record<string, string> | number | string>;

export interface Dependency {
  dependency: string;
  source: string;
  version?: string;
  package?: string;
  type?: string;
  scope?: string;
}
export type Dependencies = Record<string, Dependency[]>;

export interface Branches {
  count: number;
  defaultBranch: string;
  dependabotBranches: number;
  prBranches: number;
  staleBranches: number;
  failedBranches: number;
}

export interface Sbom {
  problematic: string[];
  warn: string[];
  unknown: string[];
}
