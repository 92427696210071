import { Operator } from 'json-rules-engine';
import {
  JSON_RULE_ENGINE_CHECK_TYPE,
  TechInsightJsonRuleCheck,
} from '../../types';

export const staleBranchesCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'staleBranchesCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Number of Stale Branches',
  description: 'Checks if there are no stale branches older than 30 days.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          all: [
            {
              fact: 'branches',
              operator: 'lessThanBranches',
              value: {
                staleBranches: 0,
              },
            },
          ],
        },
      ],
    },
  },
});

const lessThanBranches = new Operator(
  'lessThanBranches',
  (a: Record<string, any>, b: Record<string, any>) => {
    return Object.keys(b).filter(key => a[key] > b[key]).length === 0;
  },
);

export const branchOperators = [lessThanBranches];

export const branchCheckInstances = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck[] =>
  [staleBranchesCheck].map(i => i(factRetrieverIds));

export const branchChecks = branchCheckInstances([]).map(i => i.id);
