import {
  JSON_RULE_ENGINE_CHECK_TYPE,
  type TechInsightJsonRuleCheck,
} from '../../types';
import { Operator } from 'json-rules-engine';

const documentationLink =
  'https://github.com/Scout24/tech-insights-fact-checkers/blob/main/docs/checks/ComplianceChecks.md';

export const problematicDependenciesCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'problematicDependenciesCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Problematic Licenses',
  description: 'All dependencies are using acceptable licenses',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      all: [
        {
          fact: 'sbom',
          operator: 'isEmptyList',
          value: true,
          path: '$.problematic',
        },
      ],
    },
  },
  failureMetadata: { documentationLink },
});

export const warnDependenciesCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'warnDependenciesCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Redistribution Licenses',
  description:
    'All dependencies are using a license which can be redistributed',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      all: [
        {
          fact: 'sbom',
          operator: 'isEmptyList',
          value: true,
          path: '$.warn',
        },
      ],
    },
  },
  failureMetadata: { documentationLink },
});

export const unknownDependenciesCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'unknownDependenciesCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Unknown Licenses',
  description: 'All dependencies have a known license',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      all: [
        {
          fact: 'sbom',
          operator: 'isEmptyList',
          value: true,
          path: '$.unknown',
        },
      ],
    },
  },
  failureMetadata: { documentationLink },
});

const isEmptyList = new Operator('isEmptyList', (a: string[]) => {
  return a && a.length === 0;
});

export const complianceOperators = [isEmptyList];

export const complianceCheckInstances = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck[] =>
  [
    problematicDependenciesCheck,
    warnDependenciesCheck,
    unknownDependenciesCheck,
  ].map(i => i(factRetrieverIds));

export const complianceChecks = complianceCheckInstances([]).map(i => i.id);
