import { Operator } from 'json-rules-engine';
import {
  type Metadata,
  JSON_RULE_ENGINE_CHECK_TYPE,
  type TechInsightJsonRuleCheck,
} from '../../types';

export const templatePresentCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'templatePresentCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Template is used',
  description: 'Checks if the project uses one of Scout recommended templates.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      any: [
        {
          fact: 'tenpureto',
          operator: 'comparePresentTenpureto',
          value: true,
        },
      ],
    },
  },
});

const comparePresentTenpureto = new Operator(
  'comparePresentTenpureto',
  (a: Metadata, b: boolean) => a.present === b,
);

export const templateOperators = [comparePresentTenpureto];

export const templateCheckInstances = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck[] =>
  [templatePresentCheck].map(i => i(factRetrieverIds));

export const templateChecks = templateCheckInstances([]).map(i => i.id);
