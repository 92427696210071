import React, { PropsWithChildren } from 'react';
import { Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

export interface MoreInfoAccordionWrapperProps {
  title?: string;
}

export function MoreInfoAccordionWrapper(
  props: PropsWithChildren<MoreInfoAccordionWrapperProps>,
) {
  const title = props.title || 'More Information';

  return (
    <Box mt={1} mr={1}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </Box>
  );
}
