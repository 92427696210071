import { Operator } from 'json-rules-engine';
import {
  type Metadata,
  JSON_RULE_ENGINE_CHECK_TYPE,
  type TechInsightJsonRuleCheck,
} from '../../types';

export const metadataPresentCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'metadataPresentCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Metadata is present',
  description: 'Checks if the project contains a metadata.yaml file.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      all: [
        {
          fact: 'metadata',
          operator: 'comparePresentMetadata',
          value: true,
        },
      ],
    },
  },
});

export const metadataValidCheck = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck => ({
  id: 'metadataValidCheck',
  type: JSON_RULE_ENGINE_CHECK_TYPE,
  name: 'Metadata is valid',
  description:
    'Checks if the metadata.yaml content is valid and has all required content.',
  factIds: factRetrieverIds,
  rule: {
    conditions: {
      all: [
        {
          fact: 'metadata',
          operator: 'compareValidMetadata',
          value: true,
        },
        {
          fact: 'metadata',
          operator: 'comparePresentMetadata',
          value: true,
        },
      ],
    },
  },
});

const comparePresentMetadata = new Operator(
  'comparePresentMetadata',
  (a: Metadata, b: boolean) => a.present === b,
);

const compareValidMetadata = new Operator(
  'compareValidMetadata',
  (a: Metadata, b: boolean) => a.valid === b,
);

export const metadataOperators = [comparePresentMetadata, compareValidMetadata];

export const metadataCheckInstances = (
  factRetrieverIds: string[],
): TechInsightJsonRuleCheck[] =>
  [metadataPresentCheck, metadataValidCheck].map(i => i(factRetrieverIds));

export const metadataChecks = metadataCheckInstances([]).map(i => i.id);
