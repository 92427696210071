import React from 'react';
import { CheckResult } from '@backstage-community/plugin-tech-insights-common';
import { DependencyInfo } from './DependencyInfo';

export interface MoreInfoProps {
  checkResult: CheckResult;
}

export function MoreInfo(props: MoreInfoProps) {
  switch (props.checkResult.check.id) {
    case 'problematicDependenciesCheck':
      return (
        <DependencyInfo checkResult={props.checkResult} check="problematic" />
      );
    case 'warnDependenciesCheck':
      return <DependencyInfo checkResult={props.checkResult} check="warn" />;
    case 'unknownDependenciesCheck':
      return <DependencyInfo checkResult={props.checkResult} check="unknown" />;
    default:
      return <></>;
  }
}
