import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  SettingsLayout,
  UserSettingsGeneral,
  UserSettingsPage,
} from '@backstage/plugin-user-settings';
import {
  createUnifiedTheme,
  palettes,
  UnifiedThemeOptions,
  UnifiedThemeProvider,
} from '@backstage/theme';
import { ContributePage } from '@internal/plugin-contribute';
import { UntaggedResourcesDashboardPage } from '@internal/plugin-untagged-resources-dashboard';
import { AwsAccountCreationPage } from '@internal/plugin-aws-account-creation';
import CssBaseline from '@material-ui/core/CssBaseline';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import Phone from '@material-ui/icons/Phone.js';
import LightIcon from '@material-ui/icons/WbSunny';
import React from 'react';
import { Navigate, Route } from 'react-router';
import { apis } from './apis';
import { Root } from './components/Root';
import { CustomApiExplorerPage } from './components/api-docs/CustomApiExplorerPage';
import { CustomCatalogPage } from './components/catalog/CustomCatalogPage';
import { createAllAwsAccountColumns } from './components/catalog/CustomCatalogTable/factories';
import { entityPage } from './components/catalog/EntityPage';
import { AwsCatalogPage } from './components/catalog/aws/AwsCatalogPage/AwsCatalogPage';
import { searchPage } from './components/search/SearchPage';

const overrides: UnifiedThemeOptions['components'] = {
  BackstageSelect: {
    styleOverrides: {
      formControl: {
        maxWidth: undefined,
      },
    },
  },
};

const s24LightTheme = createUnifiedTheme({
  fontFamily:
    '"Make It Sans IS24 Web",Verdana,"DejaVu Sans",Arial,Helvetica,sans-serif',
  defaultPageTheme: 'home',
  palette: {
    ...palettes.light,
  },
  components: overrides,
});

const s24DarkTheme = createUnifiedTheme({
  fontFamily:
    '"Make It Sans IS24 Web",Verdana,"DejaVu Sans",Arial,Helvetica,sans-serif',
  defaultPageTheme: 'home',
  palette: {
    ...palettes.dark,
  },
  components: overrides,
});

const app = createApp({
  apis,
  icons: {
    // slack: TagIcon, TODO: available in MUI5, enable after Backstage migrates from @material-ui/icons to @mui/icons-material
    opsgenie: NotificationsActive,
    phone: Phone,
  },
  themes: [
    {
      id: 's24Dark',
      title: 'Dark',
      icon: <LightIcon />,
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={s24DarkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 's24Light',
      title: 'Light',
      icon: <LightIcon />,
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={s24LightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
  ],
  components: {
    SignInPage: props => {
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'test'
      ) {
        return <SignInPage {...props} auto providers={['guest']} />;
      }
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'microsoft-auth-provider',
            title: 'Microsoft',
            message: 'Sign In using Microsoft Azure AD',
            apiRef: microsoftAuthApiRef,
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="index.html" element={<Navigate to="/" />} />
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogPage />
    </Route>
    <Route
      path="/aws-accounts"
      element={<AwsCatalogPage columns={createAllAwsAccountColumns()} />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />}>
      <CustomApiExplorerPage />
    </Route>
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      <SettingsLayout>
        <SettingsLayout.Route path="general" title="General">
          <UserSettingsGeneral />
        </SettingsLayout.Route>
      </SettingsLayout>
    </Route>
    <Route path="/contribute" element={<ContributePage />} />
    <Route path="/tagging" element={<UntaggedResourcesDashboardPage />} />
    <Route path="/aws-account-creation" element={<AwsAccountCreationPage />} />
  </FlatRoutes>
);

const App = app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);

export default App;
