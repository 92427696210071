import React from 'react';
import { CheckResult } from '@backstage-community/plugin-tech-insights-common';
import { Box, ListItemIcon, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { MoreInfoAccordionWrapper } from './MoreInfoAccordionWrapper';

const useStyles = makeStyles({
  list: {
    overflow: 'auto',
    maxHeight: 500,
  },
});

export interface DependencyInfoProps {
  checkResult: CheckResult;
  check: 'problematic' | 'warn' | 'unknown';
}

export function DependencyInfo(props: DependencyInfoProps) {
  const { list } = useStyles();
  interface Value {
    problematic: string[];
    warn: string[];
    unknown: string[];
  }
  const value: Value = props.checkResult.facts.sbom.value as unknown as Value;
  const packageNames = value[props.check].map(decodeURIComponent);

  if (packageNames.length === 0) {
    return <></>;
  }

  return (
    <MoreInfoAccordionWrapper title="Details">
      <Box>
        <Typography>
          This is the list of dependencies that trigger this check:
        </Typography>
        <List className={list}>
          {packageNames.map(packageName => (
            <ListItem key={packageName} button>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary={packageName} />
            </ListItem>
          ))}
        </List>
      </Box>
    </MoreInfoAccordionWrapper>
  );
}
