import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  analyticsApiRef,
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';
import {
  awsResourcesApiRef,
  AwsResourcesClient,
} from '@internal/plugin-untagged-resources-dashboard';
import {
  awsAccountCreationApiRef,
  AwsAccountCreationClient,
} from '@internal/plugin-aws-account-creation';
import {
  techInsightsApiRef,
  TechInsightsClient,
} from '@backstage-community/plugin-tech-insights';
import { CustomCheckResultRenderer } from './components/catalog/CustomCheckResultRenderer';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  // Instantiate and register the GA Analytics API Implementation.
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
  }),
  createApiFactory({
    api: awsResourcesApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new AwsResourcesClient({
        discoveryApi: discoveryApi,
        identityApi: identityApi,
      }),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: techInsightsApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new TechInsightsClient({
        discoveryApi,
        identityApi,
        renderers: [CustomCheckResultRenderer],
      }),
  }),
  createApiFactory({
    api: awsAccountCreationApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new AwsAccountCreationClient({
        discoveryApi,
        identityApi,
      }),
  }),
];
