import { templateCheckInstances } from './checkers/checks/TemplateChecks';
import { metadataCheckInstances } from './checkers/checks/MetadataChecks';
import { branchCheckInstances } from './checkers/checks/BranchChecks';
import { complianceCheckInstances } from './checkers/checks/ComplianceChecks';

export {
  metadataChecks,
  metadataCheckInstances,
  metadataOperators,
} from './checkers/checks/MetadataChecks';
export {
  templateChecks,
  templateCheckInstances,
  templateOperators,
} from './checkers/checks/TemplateChecks';
export {
  branchChecks,
  branchCheckInstances,
  branchOperators,
} from './checkers/checks/BranchChecks';
export {
  complianceChecks,
  complianceCheckInstances,
  complianceOperators,
} from './checkers/checks/ComplianceChecks';

const allHeaders: Record<string, string> = {};

[
  ...metadataCheckInstances([]),
  ...templateCheckInstances([]),
  ...branchCheckInstances([]),
  ...complianceCheckInstances([]),
].forEach(check => {
  allHeaders[check.id] = check.name;
});

export const checkHeaders: Record<string, string> = { ...allHeaders };
